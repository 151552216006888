import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend, Line, ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import Loader from '../Loader';

const DoorsUsageChart = ({
  telemetries,
  group,
  debug,
  level,
  from,
  to,
  loading,
}) => {

  const gType = (groupType) => {
    switch (groupType) {
      case 'hour': return 'hours';
      case 'day': return 'days';
      case 'month': return 'months';
      default: return 'days';
    }
  };

  const format = (date) => {
    if (group === 'hour') return moment(date).startOf('hour').toDate().getTime();
    else if (group === 'month') return moment(date).startOf('month').toDate().getTime();
    return moment(date).startOf('day').toDate().getTime();
  };

  if (loading) return <Loader />;

  const dataLeft = _(telemetries.left).map((o) => {
    const date = new Date(o.generationTimestamp);
    return {
      day: format(date),
      timestamp: o.generationTimestamp,
      cartridge: o.cartridgeLevelPercent
    };
  }).groupBy('day')
    .mapValues((v) => (
      {
        uses: v.length,
        cartridge: level ? _.maxBy(v, 'timestamp').cartridge : 0
      }
    ))
    .value();

  const dataRight = _(telemetries.right).map((o) => {
    const date = new Date(o.generationTimestamp);
    return {
      day: format(date),
      timestamp: o.generationTimestamp,
      cartridge: o.cartridgeLevelPercent
    };
  }).groupBy('day')
    .mapValues((v) => (
      {
        uses: v.length,
        cartridge: level ? _.maxBy(v, 'timestamp').cartridge : 0
      }
    ))
    .value();

  const getTicks = (fromDate, toDate, groupType) => {
    const g = gType(groupType);
    const start = moment(fromDate).clone();
    const end = moment(toDate).clone();
    const res = [start.toDate().getTime()];
    while (start.add(1, g).diff(end) < 0) {
      res.push(start.toDate().getTime());
    }
    return res;
  };

  const days = getTicks(from, to, group);

  const data = _.map(days, (day) => {
    const [left, right] = [dataLeft[day], dataRight[day]];
    return {
      day,
      leftUses: left ? left.uses : undefined,
      rightUses: right ? right.uses : undefined,
      leftCartridge: left ? left.cartridge : undefined,
      rightCartridge: right ? right.cartridge : undefined,
    };
  });

  const tickFormatter = () => {
    switch (group) {
      case 'hour': return 'H[h]';
      case 'day': return 'DD';
      case 'month': return 'MMM';
      default: return 'DD';
    }
  };
  const tooltipTickFormatter = () => {
    switch (group) {
      case 'hour': return 'HH:mm';
      case 'day': return 'dddd[,] MMMM Do';
      case 'month': return 'MMMM';
      default: return 'dddd[,] MMMM Do';
    }
  };

  const domain = () => {
    const g = gType(group);
    return [moment(from).subtract(1, g).toDate().getTime(), to.getTime()];
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        width="100%"
        height="100%"
        data={data}
        margin={{
          top: 20,
          // right: 20,
          bottom: 20,
          // left: 20,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis
          dataKey="day"
          scale="time"
          domain={domain()}
          tickFormatter={(ts) => moment(new Date(ts)).format(tickFormatter())}
          type="number"
        // angle={-90}
        // textAnchor="end"
        // padding={{left: 5}}
        />
        <YAxis yAxisId="left" width={40} allowDecimals={false} tickFormatter={(n) => n >= 1000 ? `${n / 1000}K` : `${n}`} />
        {level ? <YAxis yAxisId="right" width={50} domain={[0, 100]} orientation="right" tickFormatter={((p) => `${p}%`)} /> : <></>}
        <Tooltip labelFormatter={(ts) => moment(new Date(ts)).format(tooltipTickFormatter())} />
        <Legend />
        {debug ? <Bar name="Left handle uses" yAxisId="left" dataKey="leftUses" barSize={20} fill="rgba(232, 0, 0, 0.7)" /> : <></>}
        {debug ? <Bar name="Right handle uses" yAxisId="left" dataKey="rightUses" barSize={20} fill="rgba(0, 79, 168, 0.7)" /> : <></>}
        {level ? <Line name="Cartridge level (%) (L)" connectNulls yAxisId="right" type="monotone" dataKey="leftCartridge" stroke="#e80000" /> : <></>}
        {level ? <Line name="Cartridge level (%) (R)" connectNulls yAxisId="right" type="monotone" dataKey="rightCartridge" stroke="#004fa8" /> : <></>}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

DoorsUsageChart.propTypes = {
  telemetries: PropTypes.object.isRequired,
  from: PropTypes.instanceOf(Date),
  to: PropTypes.instanceOf(Date),
  group: PropTypes.string.isRequired,
  debug: PropTypes.bool,
  level: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
};

export default DoorsUsageChart;
