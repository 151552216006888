import { CircularProgress } from '@material-ui/core';
import React from 'react';

const Loader = () => (
  <>
    <CircularProgress
      color="primary"
      style={{
        width: 50,
        height: 50,
        marginTop: -25,
        marginLeft: -25,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)'
      }}
    />
    <img
      src="/static/logo-spinner.png"
      alt="Loader logo"
      style={{
        width: 30,
        height: 30,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)'
      }}
    />
  </>
);
export default Loader;
