import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import useRoles from './useRoles';

const withRoleBasedRedirect = (Component, options) => (props) => {
  const roles = useRoles();
  const navigate = useNavigate();
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    if (roles === 'LOADING') return;
    const valid = _.includes(roles, options.validRole);
    if (!valid) navigate('/404', { replace: true });
    else setIsAuthorized(true);
  }, [roles, navigate]);

  return isAuthorized ? <Component {...props} /> : <></>;
};

export default withRoleBasedRedirect;
