import PropTypes from 'prop-types';
import React from 'react';
import {
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip
} from 'recharts';
import Loader from '../Loader';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central" >
      {percent > 0.07 ? `${(percent * 100).toFixed(0)}%` : ''}
    </text >
  );
};

const DoorsRepartitionPie = ({
  data,
  nameKey,
  dataKey,
  loading,
}) => {
  if (loading) return <Loader />;
  return (
    <ResponsiveContainer width="100%" height={360}>
      <PieChart
        width={300}
        height={300}
      >
        <Pie
          data={data}
          nameKey={nameKey}
          dataKey={dataKey}
          innerRadius={50}
          outerRadius={100}
          label={renderCustomizedLabel}
          labelLine={false}
        >
          {data.map((entry) => (<Cell key={`cell-${entry[nameKey]}`} fill={entry.color} />))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
};

DoorsRepartitionPie.propTypes = {
  data: PropTypes.array.isRequired,
  nameKey: PropTypes.string.isRequired,
  dataKey: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired
};

export default DoorsRepartitionPie;
