import { useEffect, useState } from 'react';
import useAPI from 'src/hooks/useAPI';

const useRoles = () => {
  const API = useAPI();
  const [roles, setRoles] = useState('LOADING');

  useEffect(() => {
    const getRoles = async () => {
      try {
        const currentRoles = await API.use('users/current/roles', 'GET');
        setRoles(currentRoles);
      } catch (err) {
        console.log(err);
      }
    };
    getRoles();
  }, []);

  return roles;
};

export default useRoles;
