import {
  Autocomplete, Button, Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Menu,
  MenuItem, TextField
} from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import { DataGrid } from '@mui/x-data-grid';
import _ from 'lodash';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Plus } from 'react-feather';
import validator from 'validator';
import useAPI from 'src/hooks/useAPI';

const SetAdminMenuItem = ({ user, afterSubmit }) => {
  const API = useAPI();

  const nextRoles = _.includes(user.roles, 'admin') ? [] : ['admin'];

  const handleSubmit = async () => {
    await API.use(
      'users/update',
      'POST',
      { ...user, roles: nextRoles },
    );
    afterSubmit();
  };

  return (
    <>
      <MenuItem onClick={handleSubmit}>
        {_.includes(user.roles, 'admin') ? 'Remove Admin' : 'Set Admin'}
      </MenuItem>
    </>
  );
};

SetAdminMenuItem.propTypes = {
  afterSubmit: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const EditCustomersMenuItem = ({ user, afterSubmit }) => {
  const API = useAPI();
  const [open, setOpen] = useState(false);
  const [allCustomers, setAllCustomers] = useState([]);
  const [customers, setCustomers] = useState(user.customers);

  const getAllCustomers = async () => {
    const all = await API.use('owners', 'GET');
    setAllCustomers(all);
  };

  useEffect(() => {
    getAllCustomers();
  }, [open]);

  const handleClickOpen = () => setOpen(true);
  const handleClickClose = () => setOpen(false);
  const handleSubmit = async () => {
    await API.use(
      'users/update',
      'POST',
      { ...user, customers }
    );
    afterSubmit();
    handleClickClose();
  };

  return (
    <>
      <MenuItem onClick={handleClickOpen}>
        Edit customers
      </MenuItem>
      <Dialog open={open} onClose={handleClickClose}>
        <DialogTitle>Create user</DialogTitle>
        <DialogContent>
          <Autocomplete
            multiple
            filterSelectedOptions
            isOptionEqualToValue={(opt, value) => opt.id === value.id}
            options={allCustomers}
            getOptionLabel={(option) => option.name}
            value={customers}
            onChange={(e, v) => setCustomers(v)}
            renderInput={(params) => <TextField {...params} label="Customers" variant="standard" fullWidth />}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCustomers(allCustomers)}>Select All</Button>
          <Button onClick={() => setCustomers([])}>Clear</Button>
          <Button onClick={handleSubmit}>Update</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

EditCustomersMenuItem.propTypes = {
  afterSubmit: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const UsersAdminCard = (rest) => {
  const API = useAPI();
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);

  const [email, setEmail] = useState('');
  const [allCustomers, setAllCustomers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [emailError, setEmailError] = useState(false);

  const [showActions, setShowActions] = useState({});

  const getUsers = async () => {
    const usersData = await API.use('users', 'GET');
    setUsers(usersData);
  };
  const getAllCustomers = async () => {
    const allCustomersData = await API.use('owners', 'GET');
    setAllCustomers(allCustomersData);
  };

  const handleClickOpen = () => setOpen(true);
  const handleClickClose = () => setOpen(false);
  const handleEmail = (e) => {
    const value = e.target.value;
    setEmail(value);
    if (!validator.isEmail(value)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  }
  const handleSubmit = () => {
    if (!emailError) {
      API.use(
        'users/create',
        'POST',
        {
          email,
          customers
        }
      )
        .then(() => handleClickClose());
    }
  };

  const openActions = (e, id) => {
    setShowActions({ ...showActions, [id]: e.target });
  };

  const closeActions = () => {
    setShowActions({});
  };

  const deleteUser = async (id) => {
    await API.use('users/delete', 'POST', { id });
    await getUsers();
  };

  const resetPassword = async (email) => {
    await API.use('users/reset', 'POST', { email });
    alert("Password reset email sent.");
  }

  useEffect(() => {
    getUsers();
    getAllCustomers();
  }, [open]);

  const columns = [
    { field: 'email', headerName: 'Email', flex: 2 },
    {
      field: 'authorizedCustomers',
      headerName: 'Authorized Customers',
      flex: 6,
      valueGetter: ({ row }) => {
        return JSON.stringify(_.map(row.customers, 'name'));
      },
    },
    {
      field: 'admin',
      headerName: 'Admin',
      flex: 1,
      valueGetter: ({ row }) => {
        return _.includes(row.roles, 'admin') ? 'YES' : 'NO';
      },
    },
    {
      field: 'actions',
      type: 'boolean',
      headerName: '...',
      renderCell: ({ row }) => {
        const user = row;
        return <>
          <IconButton onClick={(e) => openActions(e, user.id)}>
            <MoreHoriz />
          </IconButton>
          <Menu
            open={Boolean(showActions[user.id])}
            onClose={() => closeActions()}
            anchorEl={showActions[user.id]}
          >
            <SetAdminMenuItem user={user} afterSubmit={() => getUsers()} />
            <EditCustomersMenuItem user={user} afterSubmit={() => getUsers()} />
            <MenuItem onClick={() => resetPassword(user.email)}>Reset password</MenuItem>
            <MenuItem onClick={() => deleteUser(user.id)}>Delete user</MenuItem>
          </Menu>
        </>
      }
    }
  ];

  return (
    <Card {...rest}>
      <CardHeader
        title="My Users"
        action={(
          <IconButton onClick={handleClickOpen}>
            <Plus />
          </IconButton>
        )}
      />
      <Divider />
      <DataGrid autoHeight columns={columns} rows={users} />
      <Dialog open={open} onClose={handleClickClose}>
        <DialogTitle>Create user</DialogTitle>
        <DialogContent>
          <TextField
            variant="standard"
            label="Email"
            name="email"
            value={email}
            onChange={(e) => handleEmail(e)}
            fullWidth
            error={emailError}
            helperText={emailError ? "Invalid email format" : null}
          />
          <Autocomplete
            multiple
            filterSelectedOptions
            options={allCustomers}
            getOptionLabel={(option) => option.name}
            value={customers}
            onChange={(e, v) => setCustomers(v)}
            renderInput={(params) => <TextField {...params} label="Customers" variant="standard" fullWidth />}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={emailError} onClick={handleSubmit}>Create user</Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default UsersAdminCard;
