import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import {
  BarChart as BarChartIcon,
  Link as LinkIcon,
  LogOut as LogoutIcon, Tool as ToolIcon, Users as UsersIcon
} from 'react-feather';
import { Outlet } from 'react-router-dom';
import withRoleBasedRedirect from 'src/auth/withRoleBasedRedirect';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';

const userItems = [
  {
    href: '/app/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard'
  },
];

const adminItems = [
  {
    href: '/admin/customers',
    icon: UsersIcon,
    title: 'Manage customers'
  },
  {
    href: '/admin/users',
    icon: UsersIcon,
    title: 'Manage users'
  },
  {
    href: '/admin/handles',
    icon: ToolIcon,
    title: 'Handles',
  },
  {
    href: '/admin/pairs',
    icon: LinkIcon,
    title: 'Device pairs'
  },
];

const DashboardLayout = ({ isAdmin }) => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const { logout } = useAuth0();
  const logoutItem = {
    callback: () => logout(),
    icon: LogoutIcon,
    title: 'Logout'
  };
  const items = isAdmin ? adminItems : userItems;
  const itemsWithLogout = [...items, logoutItem];

  return (
    <div style={{ marginTop: 64 }}>
      <DashboardNavbar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <DashboardSidebar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        items={itemsWithLogout}
      />
      <Outlet />
    </div>
  );
};

DashboardLayout.propTypes = {
  isAdmin: PropTypes.bool.isRequired
};

export const UserLayout = withAuthenticationRequired(DashboardLayout, {});
export const AdminLayout = withAuthenticationRequired(
  withRoleBasedRedirect(DashboardLayout, { validRole: 'admin' }),
  {},
);
