import moment from "moment";

export const cartridgeLevel = (last) => ({ left: last && last.left ? last.left.cartridgeLevelPercent : 0, right: last && last.right ? last.right.cartridgeLevelPercent : 0 });
export const batteryLevel = (last) => {
	const { left, right } = { left: last && last.left ? last.left.batteryLevel : 0, right: last && last.right ? last.right.batteryLevel : 0 };
	const getString = (level) => {
		switch (level) {
			case 0: return "EMPTY";
			case 1: return "LOW";
			case 2: return "GOOD";
			case 3: return "FULL";
			default: return "?"
		}
	}
	return { left: getString(left), right: getString(right) };
};

export const esp = (infos) => {
	if (!infos) return 'ESP: ...';
	const leftEsp = infos.left && infos.left.assetESPMajor ? `${infos.left.assetESPMajor}.${infos.left.assetESPMinor}.${infos.left.assetESPBuild}` : '?';
	const rightEsp = infos.right && infos.right.assetESPMajor ? `${infos.right.assetESPMajor}.${infos.right.assetESPMinor}.${infos.right.assetESPBuild}` : '?';
	return `ESP: ${leftEsp} / ${rightEsp}`;
};

export const assetSTM = (infos) => {
	if (!infos) return 'STM: ...';
	const leftStm = infos.left && infos.left.assetSTMMajor ? `${infos.left.assetSTMMajor}.${infos.left.assetSTMMinor}.${infos.left.assetSTMBuild}` : '?';
	const rightStm = infos.right && infos.right.assetSTMMajor ? `${infos.right.assetSTMMajor}.${infos.right.assetSTMMinor}.${infos.right.assetSTMBuild}` : '?';
	return `STM: ${leftStm} / ${rightStm}`;
};

export const mac = (infos) => {
	if (!infos) return 'MAC: ...';
	const leftMac = infos.left && infos.left.macAddress ? infos.left.macAddress : '?';
	const rightMac = infos.right && infos.right.macAddress ? infos.right.macAddress : '?';
	return `MAC: ${leftMac} / ${rightMac}`;
};

export const sni = (infos) => {
	if (!infos) return 'SNI: ...';
	const leftMac = infos.left && infos.left.deviceId ? infos.left.deviceId : '?';
	const rightMac = infos.right && infos.right.deviceId ? infos.right.deviceId : '?';
	return `SNI: ${leftMac} / ${rightMac}`;
};

export const ip = (infos) => {
	if (!infos) return 'IP: ...';
	const leftIp = infos.left && infos.left.ip ? infos.left.ip : '?';
	const rightIp = infos.right && infos.right.ip ? infos.right.ip : '?';
	return `IP: ${leftIp} / ${rightIp}`;
};

export const csn = (infos) => {
	if (!infos) return 'Cart. SN: ...';
	const leftCSN = infos.left && infos.left.cartridgeSN ? infos.left.cartridgeSN : '?';
	const rightCSN = infos.right && infos.right.cartridgeSN ? infos.right.cartridgeSN : '?';
	return `Cart. SN: ${leftCSN} / ${rightCSN}`;
};

export const cv = (infos) => {
	if (!infos) return 'Cart. V: ...';
	const leftCV = infos.left && infos.left.cartridgeVersion ? infos.left.cartridgeVersion : '?';
	const rightCV = infos.right && infos.right.cartridgeVersion ? infos.right.cartridgeVersion : '?';
	return `Cart. V: ${leftCV} / ${rightCV}`;
};

export const lastSent = (infos, prefix) => {
	if (!infos) return `${prefix}: ...`;
	const leftST = infos.left && infos.left.sendingTimestamp ? infos.left.sendingTimestamp : null;
	const rightST = infos.right && infos.right.sendingTimestamp ? infos.right.sendingTimestamp : null;
	const left = leftST ? moment(leftST).format('YYYY-MM-DD HH:mm') : '?';
	const right = rightST ? moment(rightST).format('YYYY-MM-DD HH:mm') : '?';
	return `${prefix}: ${left} / ${right}`;
};

export const lastUpdate = (timestamp) => {
	if (!timestamp) return '?';
	// return moment(timestamp).format('YY-MM-DD HH:mm');
	return moment(timestamp).fromNow();
}
