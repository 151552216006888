import {
  Box,
  Button, Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
import {
  ArrowForward,
  FiberManualRecord
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader';

const DoorsTable = ({ doors, loading }) => {
  const [limit, setLimit] = useState(4);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  if (loading) return <Loader />;

  return (
    <>
      <Box sx={{ width: '100%', display: 'block' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>
                Location
              </TableCell>
              <TableCell align="right" sx={{ display: { lg: 'table-cell', xs: 'none' } }}>
                Cartridge level (L/R)
              </TableCell>
              <TableCell align="right">
                Uses (L/R)
              </TableCell>
              <TableCell align="center" style={{ width: 10 }}>
                ...
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {doors.slice(page * limit, (page + 1) * limit).map((door) => (
              <TableRow
                hover
                key={door.id}
              >
                <TableCell style={{ width: 5 }} align="center">
                  <FiberManualRecord style={{ color: door.color, fontSize: 10 }} />
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex'
                    }}
                  >
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {door.location}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell align="right" sx={{ display: { lg: 'table-cell', xs: 'none' } }}>
                  {`${door.last.left ? door.last.left.cartridgeLevelPercent : '?'}% / ${door.last.right ? door.last.right.cartridgeLevelPercent : '?'}%`}
                </TableCell>
                <TableCell align="right">
                  {`${door.usesLeft} / ${door.usesRight}`}
                </TableCell>
                <TableCell align="right">
                  <Button onClick={() => navigate(`/app/${door.id}`)}>
                    <ArrowForward />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={doors.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[4, 10, 25]}
        />
      </Box>
    </>
  );
};

DoorsTable.propTypes = {
  doors: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired
};

export default DoorsTable;
