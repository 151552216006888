import {
  Box, Container, Typography
} from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';
import CustomersAdminCard from 'src/components/admin/CustomersAdminCard';

const Customers = () => (
  <>
    <Helmet>
      <title>Customers | Tweaq Analytics</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { lg: 'row', xs: 'column' },
            justifyContent: { lg: 'space-between', xs: 'center' },
            alignItems: 'center',
            alignContent: 'center',
            marginBottom: 3,
          }}
        >
          <Typography variant="h2" gutterBottom sx={{ margin: 0, marginBottom: { xs: 2, lg: 0 } }}>Customers</Typography>
        </Box>
        <CustomersAdminCard />
      </Container>
    </Box>
  </>
);

export default Customers;
