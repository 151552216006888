import {
  Autocomplete,
  Box, Button, Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton, TextField
} from '@material-ui/core';
import { ArrowForward, DeleteForever, ImportExport } from '@material-ui/icons';
import { DatePicker, LocalizationProvider } from '@material-ui/lab';
import AdapterMoment from '@material-ui/lab/AdapterMoment';
import { DataGrid } from '@mui/x-data-grid';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Plus } from 'react-feather';
import { useNavigate } from 'react-router';
import useAPI from 'src/hooks/useAPI';

const DoorsAdminCard = (rest) => {
  const API = useAPI();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [doors, setDoors] = useState([]);
  const [allCustomers, setAllCustomers] = useState([]);
  const [allVirtualDevices, setAllVirtualDevices] = useState([]);

  const [from, setFrom] = useState(moment().startOf('month'));
  const [to, setTo] = useState(moment().endOf('day'));

  const [customer, setCustomer] = useState(null);
  const [location, setLocation] = useState(null);
  const [left, setLeft] = useState(null);
  const [right, setRight] = useState(null);

  const csvLink = useRef();

  const getDoors = async (fromMoment, toMoment) => {
    const doorsData = await API.use('door/admin/table', 'GET', { from: fromMoment.valueOf(), to: toMoment.valueOf() });
    setDoors(doorsData);
  };

  const getAllCustomers = async () => {
    const allCustomersData = await API.use('owners', 'GET');
    setAllCustomers(allCustomersData);
  };

  const getAllVirtualDevices = async () => {
    const allVirtualDevicesData = await API.use('virtual-device/availables', 'GET');
    setAllVirtualDevices(allVirtualDevicesData);
  };

  const deleteDoor = async (id) => {
    await API.use('door/delete', 'POST', { id });
    await getDoors(from, to);
  };

  const handleClickOpen = () => setOpen(true);
  const handleClickClose = () => setOpen(false);
  const handleSubmit = () => {
    API.use(
      'door/create',
      'POST',
      {
        location,
        leftId: left ? left.id : undefined,
        rightId: right ? right.id : undefined,
        ownerId: customer.id
      }
    )
      .then(() => handleClickClose());
  };

  useEffect(() => {
    getDoors(from, to);
    getAllCustomers();
    getAllVirtualDevices();
  }, [open, from, to]);

  const columns = [
    { field: 'owner', headerName: 'Customer', flex: 1, valueGetter: ({ row }) => row.owner },
    { field: 'location', headerName: 'Location', flex: 1, valueGetter: ({ row }) => row.location },
    {
      field: 'cartridgeLevelLeft',
      headerName: 'Cart. (L)',
      type: 'number',
      flex: 1,
      valueGetter: ({ row }) => {
        const last = row.last;
        return last.left ? last.left.cartridgeLevelPercent / 100 : -1;
      },
      valueFormatter: (p) => `${p.value >= 0 ? Math.round(p.value * 100) : '?'}%`
    },
    {
      field: 'cartridgeLevelRight',
      headerName: 'Cart. (R)',
      type: 'number',
      flex: 1,
      valueGetter: ({ row }) => {
        const last = row.last;
        return last.right ? last.right.cartridgeLevelPercent / 100 : -1;
      },
      valueFormatter: (p) => `${p.value >= 0 ? Math.round(p.value * 100) : '?'}%`
    },
    {
      field: 'batterylevelLeft',
      headerName: 'Bat. (L)',
      type: 'number',
      flex: 1,
      valueGetter: ({ row }) => {
        const last = row.last;
        return last.left ? last.left.batteryLevel : -1;
      },
      valueFormatter: (p) => `${p.value >= 0 ? p.value : '?'}`
    },
    {
      field: 'batterylevelRight',
      headerName: 'Bat. (R)',
      type: 'number',
      flex: 1,
      valueGetter: ({ row }) => {
        const last = row.last;
        return last.right ? last.right.batteryLevel : -1;
      },
      valueFormatter: (p) => `${p.value >= 0 ? p.value : '?'}`
    },
    {
      field: 'usesLeft',
      headerName: 'Uses (L)',
      type: 'number',
      flex: 1,
      valueGetter: ({ row }) => {
        const uses = row.uses;
        return `${uses.left ? uses.left : 0}`;
      },
    },
    {
      field: 'usesRight',
      headerName: 'Uses (R)',
      type: 'number',
      flex: 1,
      valueGetter: ({ row }) => {
        const uses = row.uses;
        return `${uses.right ? uses.right : 0}`;
      },
    },
    {
      field: 'cartridgesUsed',
      headerName: 'Cart. used',
      type: 'number',
      flex: 1,
      valueGetter: ({ row }) => {
        const cartridgesUsed = row.cartridgesUsed;
        return `${(cartridgesUsed.left ? cartridgesUsed.left : 0) + (cartridgesUsed.right ? cartridgesUsed.right : 0)}`;
      },
    },
    {
      field: 'actions',
      disableExport: true,
      type: 'boolean',
      headerName: '...',
      renderCell: (door) => (
        <>
          <IconButton>
            <DeleteForever onClick={() => deleteDoor(door.id)} />
          </IconButton>
          <IconButton onClick={() => navigate(`/admin/${door.id}`)}>
            <ArrowForward />
          </IconButton>
        </>
      )
    }
  ];

  const columnsKeyed = _(columns).filter('valueGetter').keyBy('headerName');

  return (
    <Card {...rest}>
      <CardHeader
        title="My Doors"
        action={(
          <>
            <IconButton onClick={handleClickOpen}>
              <Plus />
            </IconButton>
            <IconButton onClick={() => csvLink.current.link.click()}>
              <ImportExport />
            </IconButton>
            <CSVLink
              data={
                doors.map((door) => _(columnsKeyed).mapValues((col) => col.valueGetter({ row: door })).value())
              }
              filename={`${from.format('YYYY.MM.DD')}-${to.format('YYYY.MM.DD')}.csv`}
              style={{ display: 'none' }}
              ref={csvLink}
              target="_blank"
            />
          </>
        )}
      />
      <Divider />
      <Box sx={{ margin: 2, display: 'flex', justifyContent: 'space-between' }}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="From"
            value={from}
            onChange={setFrom}
            renderInput={(params) => <TextField {...params} variant="standard" />}
          />
          <DatePicker
            label="To"
            value={to}
            onChange={setTo}
            renderInput={(params) => <TextField {...params} variant="standard" />}
          />
        </LocalizationProvider>
      </Box>
      <Box>
        <DataGrid autoHeight columns={columns} rows={doors} />
      </Box>
      <Dialog open={open} onClose={handleClickClose}>
        <DialogTitle>Create door</DialogTitle>
        <DialogContent>
          <Autocomplete
            options={allCustomers}
            getOptionLabel={(option) => option.name}
            value={customer}
            onChange={(e, v) => setCustomer(v)}
            renderInput={(params) => <TextField {...params} label="Customer" variant="standard" fullWidth />}
          />
          <TextField variant="standard" label="Location" name="location" value={location} onChange={(e) => setLocation(e.target.value)} fullWidth />
          <Autocomplete
            options={allVirtualDevices}
            getOptionLabel={(option) => `(${option.leftRight}) ${option.name}`}
            value={left}
            onChange={(e, v) => setLeft(v)}
            renderInput={(params) => <TextField {...params} label="Left handle" variant="standard" fullWidth />}
          />
          <Autocomplete
            options={allVirtualDevices}
            getOptionLabel={(option) => `(${option.leftRight}) ${option.name}`}
            value={right}
            onChange={(e, v) => setRight(v)}
            renderInput={(params) => <TextField {...params} label="Right handle" variant="standard" fullWidth />}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Create door</Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default DoorsAdminCard;
