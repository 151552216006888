import moment from 'moment';

export const from = (p) => {
  switch (p) {
    case 'TODAY':
      return moment().startOf('day').toDate();
    case 'YESTERDAY':
      return moment().subtract(1, 'days').startOf('day').toDate();
    case 'LAST 7 DAYS':
      return moment().subtract(6, 'days').startOf('day').toDate();
    case 'CURRENT MONTH':
      return moment().startOf('month').toDate();
    case 'LAST MONTH':
      return moment().subtract(1, 'months').startOf('month').toDate();
    case 'CURRENT YEAR':
      return moment().startOf('year').toDate();
    case 'LAST YEAR':
      return moment().subtract(1, 'years').startOf('year').toDate();
    default: throw new Error();
  }
};

export const to = (p) => {
  switch (p) {
    case 'TODAY':
      return moment().endOf('day').toDate();
    case 'YESTERDAY':
      return moment().subtract(1, 'days').endOf('day').toDate();
    case 'LAST 7 DAYS':
      return moment().endOf('day').toDate();
    case 'CURRENT MONTH':
      return moment().endOf('month').toDate();
    case 'LAST MONTH':
      return moment().subtract(1, 'months').endOf('month').toDate();
    case 'CURRENT YEAR':
      return moment().endOf('year').toDate();
    case 'LAST YEAR':
      return moment().subtract(1, 'years').endOf('year').toDate();
    default: throw new Error();
  }
};

export const group = (p) => {
  if (p === 'TODAY' || p === 'YESTERDAY') return 'hour';
  if (p === 'CURRENT YEAR' || p === 'LAST YEAR') return 'month';
  return 'day';
};

export const subheader = (period) => {
  switch (group(period)) {
    case 'hour': return moment(from(period)).format('DD MMMM YYYY');
    case 'day': return moment(from(period)).format('MMMM YYYY');
    case 'month': return moment(from(period)).format('YYYY');
  }
};
