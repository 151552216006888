const colors = {
	palette: [
		'#6fb1fc',
		'#ff9452',
		'#deaeff',
		'#7df0f3',
		'#83e48d',
		'#e1c061',
	],
	other: '#b3b3b1',
};

export default colors;
