import React from 'react';
import { Navigate } from 'react-router-dom';
import MainLayout from 'src/components/MainLayout';
import { AdminLayout, UserLayout } from './components/DashboardLayout';
import Customers from './pages/admin/customers';
import Doors from './pages/admin/doors';
import Users from './pages/admin/users';
import VirtualDevices from './pages/admin/handles';
import HandleSingle from './pages/doorId';
import HandleSingleAdmin from './pages/admin/doorId';
import NotFound from './pages/404';
import Dashboard from './pages/dashboard';

const routes = () => [
  {
    path: 'app',
    element: <UserLayout isAdmin={false} />,
    children: [
      { path: '/:handleId', element: <HandleSingle /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'admin',
    element: <AdminLayout isAdmin />,
    children: [
      { path: '/', element: <Navigate to="/admin/customers" /> },
      // { path: 'dashboard', element: <AdminDashboard /> },
      { path: 'customers', element: <Customers /> },
      { path: 'users', element: <Users /> },
      { path: 'handles', element: <VirtualDevices /> },
      { path: 'pairs', element: <Doors /> },
      { path: '/:handleId', element: <HandleSingleAdmin /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      // { path: 'login', element: <Login /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
