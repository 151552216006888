import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  IconButton,
  Toolbar
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from './Logo';

const DashboardNavbar = ({ onMobileNavOpen, ...rest }) => {
  const [display, setDisplay] = useState('block');

  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollY > 150;
      if (scroll) setDisplay('none');
      else setDisplay('block');
    };
    document.addEventListener('scroll', handleScroll);
    return () => document.removeEventListener('scroll', handleScroll);
  });

  return (
    <>
      <AppBar
        elevation={0}
        position="fixed"
        style={{ background: '#000', display, height: 64 }}
        {...rest}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
            sx={{ display: 'block' }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <RouterLink to="/">
            <Logo style={{ height: 40 }} />
          </RouterLink>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: 'block', width: 48 }} />
        </Toolbar>
      </AppBar>
    </>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func,
};

export default DashboardNavbar;
