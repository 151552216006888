import {
  Box, Card, CardContent, CardHeader, Container, Divider, Grid,
  MenuItem,
  Select, Typography
} from '@material-ui/core';
import { Autorenew, Speed } from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import HandleCard from 'src/components/HandleCard';
import Loader from 'src/components/Loader';
import DoorsRepartitionPie from 'src/components/plots/DoorsRepartitionPie';
import DoorsUsageChart from 'src/components/plots/DoorsUsageChart';
import DoorsTable from 'src/components/tables/DoorsTable';
import useAPI from 'src/hooks/useAPI';
import colors from 'src/theme/colors';
import { from, group, subheader, to } from '../../utils/period';

const TotalUses = ({ period, data }) => {

  const totalUses = () => {
    let left;
    let right;
    if (!data || _.isEmpty(data)) {
      left = 0;
      right = 0;
    } else {
      left = _.sumBy(data, 'usesLeft');
      right = _.sumBy(data, 'usesRight');
    }
    return { left, right };
  };

  const { left, right } = totalUses();

  return (
    <HandleCard
      title="Total uses (L/R)"
      loading={_.isNull(data)}
      value={`${left} / ${right}`}
      color="#6fb1fc"
      icon={<Speed />}
    >
      <Typography variant="body1">{_.startCase(_.camelCase(period))}</Typography>
    </HandleCard>
  );
};

TotalUses.propTypes = {
  period: PropTypes.string.isRequired,
  data: PropTypes.any.isRequired
};

const CartridgesUsed = ({ period, data }) => {

  const cartridgesUsed = () => {
    let left;
    let right;
    if (!data || _.isEmpty(data)) {
      left = 0;
      right = 0;
    } else {
      left = _.sumBy(data, 'cartridgesUsed.left');
      right = _.sumBy(data, 'cartridgesUsed.right');
    }
    return { left, right };
  };

  const { left, right } = cartridgesUsed();

  return (
    <HandleCard
      title="Cartridges used (L/R)"
      loading={_.isNull(data)}
      value={`${left} / ${right}`}
      color="#ff9452"
      icon={<Autorenew />}
    >
      <Typography variant="body1">{_.startCase(_.camelCase(period))}</Typography>
    </HandleCard>
  );
};

CartridgesUsed.propTypes = {
  period: PropTypes.string.isRequired,
  data: PropTypes.any.isRequired
};

const Repartition = ({ period, table, pie }) => {
  const isLoading = (t, p) => !t || !p;

  return (
    <>
      <Grid
        item
        xs={12}
        lg={8}
      >
        <Card style={{ height: '100%', position: 'relative' }}>
          <CardHeader title="My Doors" />
          <Divider />
          <CardContent style={{ height: 'calc(100% - 54px)', position: 'relative', display: 'flex', alignItems: 'center', minHeight: 300 }}>
            <DoorsTable doors={table} loading={isLoading(table, pie)} />
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        xs={12}
        lg={4}
      >
        <Card style={{ height: '100%', position: 'relative' }}>
          <CardHeader title="Usage distribution" />
          <Divider />
          <CardContent style={{ height: 'calc(100% - 54px)', position: 'relative', display: 'flex', alignItems: 'center', minHeight: 300 }}>
            <DoorsRepartitionPie data={pie} nameKey="location" dataKey="uses" loading={isLoading(table, pie)} />
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

Repartition.propTypes = {
  period: PropTypes.string.isRequired,
  table: PropTypes.any.isRequired,
  pie: PropTypes.any.isRequired
};

const Graph = ({ period }) => {
  const API = useAPI();
  const [telemetries, setTelemetries] = useState(null);
  const getTelemetries = async () => {
    setTelemetries(null);
    const data = await API.use('door/telemetries', 'GET', { from: from(period).getTime(), to: to(period).getTime() });
    setTelemetries(data);
  };

  useEffect(() => {
    getTelemetries();
  }, [period]);

  if (!telemetries) return <Loader />;

  return (
    <DoorsUsageChart
      sx={{ height: 600, maxHeight: 'max(100vh, 400px)' }}
      telemetries={telemetries}
      from={from(period)}
      to={to(period)}
      group={group(period)}
      debug
    />
  );
};

Graph.propTypes = {
  period: PropTypes.string.isRequired
};

const Dashboard = () => {
  const API = useAPI();
  const [period, setPeriod] = useState('CURRENT MONTH');
  const [table, setTable] = useState(null);
  const [pie, setPie] = useState(null);

  const getTable = async () => {
    setTable(null);
    setPie(null);
    const raw = await API.use('door/table', 'GET', { from: from(period).getTime(), to: to(period).getTime() });
    const data = _.map(raw, (obj) => ({
      ...obj,
      uses: obj.uses.left + obj.uses.right,
      usesLeft: obj.uses.left,
      usesRight: obj.uses.right,
    }));
    const total = _.sumBy(data, 'uses');
    const withColors = _(data).orderBy('uses', 'desc').map((item, idx) => {
      if (idx < colors.palette.length && item.uses / total >= 0.08) return { ...item, color: colors.palette[idx] };
      return { ...item, color: colors.other };
    }).value();
    const pieData = _(withColors).groupBy('color').map((arr) => {
      if (arr.length === 1 && arr[0].color !== colors.other) {
        const { location, color, uses } = arr[0];
        return { location, color, uses };
      }
      return {
        location: `Others (${arr.length})`,
        color: colors.other,
        uses: _.sumBy(arr, 'uses'),
      };
    }).value();
    setTable(withColors);
    setPie(pieData);
  };

  useEffect(() => {
    getTable();
  }, [period]);

  const handleChange = (e) => {
    setPeriod(e.target.value);
  };

  return (
    <>
      <Helmet>
        <title>Dashboard | Tweaq Analytics</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { lg: 'row', xs: 'column' },
              justifyContent: { lg: 'space-between', xs: 'center' },
              alignItems: 'center',
              alignContent: 'center',
              marginBottom: 3,
            }}
          >
            {/* <Box> */}
            <Typography variant="h2" gutterBottom sx={{ margin: 0, marginBottom: { xs: 2, lg: 0 } }}>My account</Typography>
            {/* </Box> */}
            <Box>
              <Select
                value={period}
                sx={{ width: 200 }}
                variant="standard"
                onChange={(e) => handleChange(e)}
              >
                <MenuItem value="TODAY">TODAY</MenuItem>
                <MenuItem value="YESTERDAY">YESTERDAY</MenuItem>
                <MenuItem value="LAST 7 DAYS">LAST 7 DAYS</MenuItem>
                <MenuItem value="CURRENT MONTH">CURRENT MONTH</MenuItem>
                <MenuItem value="LAST MONTH">LAST MONTH</MenuItem>
                <MenuItem value="CURRENT YEAR">CURRENT YEAR</MenuItem>
                <MenuItem value="LAST YEAR">LAST YEAR</MenuItem>
              </Select>
            </Box>
          </Box>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={6}
              sm={6}
              xl={6}
              xs={12}
            >
              <TotalUses period={period} data={table} />
            </Grid>
            <Grid
              item
              lg={6}
              sm={6}
              xl={6}
              xs={12}
            >
              <CartridgesUsed period={period} data={table} />
            </Grid>
            <Repartition period={period} table={table} pie={pie} />
            <Grid
              item
              xs={12}
            >
              <Card sx={{ height: 600, maxHeight: 'max(100vh, 400px)' }}>
                <CardHeader
                  title="Usage analytics"
                  subheader={subheader(period)}
                />
                <Divider />
                <CardContent sx={{ width: '100%', height: '100%' }}>
                  <Box
                    sx={{
                      height: 'calc(100% - 54px)',
                      position: 'relative'
                    }}
                  >
                    <Graph period={period} />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Dashboard;
