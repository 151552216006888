import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';

class API {
  constructor(getToken) {
    this.getToken = getToken;
  }

  async use(path, method, data) {
    const token = await this.getToken();
    const body = method === 'GET' ? undefined : JSON.stringify(data);
    let params = '';
    if (method === 'GET' && data) {
      const encoded = _(data).map((val, key) => `${key}=${encodeURIComponent(val)}`).join('&');
      params = `?${encoded}`;
    }
    const result = await fetch(`${process.env.REACT_APP_SERVER_URL}/${path}${params}`, {
      // const result = await fetch(`http://localhost:3001/${path}${params}`, {
      method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body,
    }).then((res) => res.json());
    return result;
  }
}

const useAPI = () => {
  const { getAccessTokenSilently } = useAuth0();
  // const getAccessTokenSilently = () => Promise.resolve('abc');
  return new API(getAccessTokenSilently);
};

export default useAPI;
