import {
  Autocomplete,
  Box, Button, Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton, Menu, MenuItem, Radio, RadioGroup, TextField
} from '@material-ui/core';
import { ImportExport, MoreHoriz } from '@material-ui/icons';
import { DatePicker, LocalizationProvider } from '@material-ui/lab';
import AdapterMoment from '@material-ui/lab/AdapterMoment';
import { DataGrid } from '@mui/x-data-grid';
import _ from 'lodash';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Plus } from 'react-feather';
import useAPI from 'src/hooks/useAPI';

const CreateButton = ({ afterSubmit }) => {
  const API = useAPI();
  const [open, setOpen] = useState(false);
  const [allDevices, setAllDevices] = useState([]);
  const [name, setName] = useState('');
  const [device, setDevice] = useState({ id: '' });
  const [dataFrom, setDataFrom] = useState(moment().startOf('day'));

  const getAllDevices = async () => {
    const allDevicesData = await API.use('handle-device/free', 'GET');
    setAllDevices(allDevicesData);
  };

  useEffect(() => {
    getAllDevices();
  }, [open]);

  const handleClickOpen = () => setOpen(true);
  const handleClickClose = () => setOpen(false);
  const handleSubmit = async () => {
    await API.use(
      'virtual-device/create',
      'POST',
      {
        name,
        deviceId: device.id,
        from: dataFrom.valueOf(),
      }
    );
    afterSubmit();
    handleClickClose();
  };

  return (
    <>
      <IconButton onClick={handleClickOpen}>
        <Plus />
      </IconButton>
      <Dialog open={open} onClose={handleClickClose}>
        <DialogTitle>Create door</DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <TextField variant="standard" label="Name" name="location" value={name} onChange={(e) => setName(e.target.value)} fullWidth />
            <Autocomplete
              options={allDevices}
              getOptionLabel={(option) => option.id}
              value={device}
              onChange={(e, v) => setDevice(v)}
              renderInput={(params) => <TextField {...params} label="Device UID" variant="standard" fullWidth />}
            />
            <DatePicker
              label="Start date"
              value={dataFrom}
              onChange={setDataFrom}
              renderInput={(params) => <TextField {...params} variant="standard" fullWidth />}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Create virtual device</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

CreateButton.propTypes = {
  afterSubmit: PropTypes.func.isRequired,
};

const ImportButton = () => {
  const API = useAPI();
  const [open, setOpen] = useState(false);
  const [ids, setIds] = useState('');
  const [leftRight, setLeftRight] = useState('L');

  const handleClickOpen = () => setOpen(true);
  const handleClickClose = () => setOpen(false);
  const handleSubmit = async () => {
    if (ids === '') return;
    else {
      await API.use(
        'handle-device',
        'POST',
        {
          leftRight,
          ids: _.split(ids, '\n'),
        }
      );
    }
    handleClickClose();
  };

  return (
    <>
      <IconButton onClick={handleClickOpen}>
        <ImportExport />
      </IconButton>
      <Dialog open={open} onClose={handleClickClose}>
        <DialogTitle>Import physical devices</DialogTitle>
        <DialogContent>
          <Box>
            <FormControl component="fieldset">
              <RadioGroup
                row
                value={leftRight}
                onChange={(e) => setLeftRight(e.target.value)}
              >
                <FormControlLabel value="L" control={<Radio />} label="L" />
                <FormControlLabel value="R" control={<Radio />} label="R" />
              </RadioGroup>
            </FormControl>
          </Box>
          <TextField multiline value={ids} onChange={(e) => setIds(e.target.value)} label="IDs" rows={10} maxRows={10} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Import physical devices</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const RenameMenuItem = ({ id, afterSubmit }) => {
  const API = useAPI();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');

  const handleClickOpen = () => setOpen(true);
  const handleClickClose = () => setOpen(false);
  const handleSubmit = async () => {
    await API.use(
      'virtual-device/rename',
      'POST',
      {
        name,
        id,
      }
    );
    afterSubmit();
    handleClickClose();
  };

  return (
    <>
      <MenuItem onClick={handleClickOpen}>
        Rename
      </MenuItem>
      <Dialog open={open} onClose={handleClickClose}>
        <DialogTitle>Create door</DialogTitle>
        <DialogContent>
          <TextField variant="standard" label="Name" name="location" value={name} onChange={(e) => setName(e.target.value)} fullWidth />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Create virtual device</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

RenameMenuItem.propTypes = {
  afterSubmit: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired
};

const ReplaceMenuItem = ({ id, history, afterSubmit }) => {
  const API = useAPI();
  const [open, setOpen] = useState(false);
  const [allDevices, setAllDevices] = useState([]);
  const [device, setDevice] = useState({ id: '' });
  const [dataFrom, setDataFrom] = useState(moment().startOf('day'));

  const getAllDevices = async () => {
    const allDevicesData = await API.use('handle-device/free', 'GET');
    setAllDevices(allDevicesData);
  };

  const isDateForbidden = (date) => {
    const last = moment(_.last(history).from);
    return last.isAfter(date);
  };

  useEffect(() => {
    getAllDevices();
  }, [open]);

  const handleClickOpen = () => setOpen(true);
  const handleClickClose = () => setOpen(false);
  const handleSubmit = async () => {
    await API.use(
      'virtual-device/replace',
      'POST',
      {
        id,
        deviceId: device.id,
        from: dataFrom.valueOf(),
      }
    );
    afterSubmit();
    handleClickClose();
  };

  return (
    <>
      <MenuItem onClick={handleClickOpen}>
        Replace physical device
      </MenuItem>
      <Dialog open={open} onClose={handleClickClose}>
        <DialogTitle>Create door</DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Autocomplete
              options={allDevices}
              getOptionLabel={(option) => option.id}
              value={device}
              onChange={(e, v) => setDevice(v)}
              renderInput={(params) => <TextField {...params} label="Device UID" variant="standard" fullWidth />}
            />
            <DatePicker
              label="Start date"
              value={dataFrom}
              onChange={setDataFrom}
              renderInput={(params) => <TextField {...params} variant="standard" fullWidth />}
              shouldDisableDate={isDateForbidden}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Create virtual device</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ReplaceMenuItem.propTypes = {
  afterSubmit: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  history: PropTypes.array.isRequired,
};

const DevicesAdminCard = (rest) => {
  const API = useAPI();
  const [showActions, setShowActions] = useState({});
  const [virtualDevices, setVirtualDevices] = useState([]);

  const getVirtualDevices = async () => {
    const all = await API.use('virtual-device/list', 'GET');
    setVirtualDevices(all);
  };

  const deleteVirtualDevice = async (id) => {
    await API.use('virtual-device/delete', 'POST', { id });
    await getVirtualDevices();
  };

  const openActions = (e, id) => {
    setShowActions({ ...showActions, [id]: e.target });
  };

  const closeActions = () => {
    setShowActions({});
  };

  useEffect(() => {
    getVirtualDevices();
  }, []);

  const columns = [
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'customer', headerName: 'Customer', flex: 1 },
    {
      field: 'currentUid',
      headerName: 'Current UID',
      flex: 1,
      valueGetter: ({ row }) => _.last(row.deviceHistory).deviceId
    },
    {
      field: 'since',
      headerName: 'Since',
      flex: 1,
      valueGetter: ({ row }) => moment(_.last(row.deviceHistory).from).format('YYYY-MM-DD')
    },
    { field: 'leftRight', headerName: 'L/R', flex: 1 },
    {
      field: 'actions',
      type: 'boolean',
      headerName: '...',
      renderCell: ({ row }) => (
        <>
          <IconButton onClick={(e) => openActions(e, row.id)}>
            <MoreHoriz />
          </IconButton>
          <Menu
            open={Boolean(showActions[row.id])}
            onClose={() => closeActions()}
            anchorEl={showActions[row.id]}
          >
            <ReplaceMenuItem id={row.id} history={row.deviceHistory} afterSubmit={() => getVirtualDevices()} />
            <RenameMenuItem id={row.id} afterSubmit={() => getVirtualDevices()} />
            <MenuItem onClick={() => deleteVirtualDevice(row.id)} disabled={!_.isNull(row.ownerId)}>Delete</MenuItem>
          </Menu>
        </>
      )
    }
  ]

  return (
    <Card {...rest}>
      <CardHeader
        title="Handles"
        action={(
          <>
            <CreateButton afterSubmit={() => getVirtualDevices()} />
            <ImportButton />
          </>
        )}
      />
      <Divider />
      <Box>
        <DataGrid autoHeight columns={columns} rows={virtualDevices} />
      </Box>
    </Card>
  );
};

export default DevicesAdminCard;
