import React from 'react';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';
import { PropTypes } from 'prop-types';
import Loader from './Loader';

const HandleCard = ({
  title,
  loading,
  value,
  icon,
  children,
  color,
  ...rest
}) => (
  <Card
    sx={{ minHeight: 100, height: '100%', position: 'relative' }}
    {...rest}
  >
    {!loading
      ? (
        <CardContent>
          <Grid
            container
            spacing={3}
            sx={{ justifyContent: 'space-between' }}
          >
            <Grid item>
              <Typography
                color="primary"
                gutterBottom
                variant="h6"
              >
                {title}
              </Typography>
              <Typography
                color="textPrimary"
                variant="h4"
              >
                {value}
              </Typography>
            </Grid>
            <Grid item>
              <Avatar
                sx={{
                  backgroundColor: color,
                  color: 'fff',
                  height: 56,
                  width: 56
                }}
              >
                {icon}
              </Avatar>
            </Grid>
          </Grid>
          <Box sx={{ mt: 2 }}>
            {children}
          </Box>
        </CardContent>
      )
      : <Loader />}
  </Card>
);

HandleCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
  icon: PropTypes.node,
  color: PropTypes.string.isRequired,
  children: PropTypes.node,
  loading: PropTypes.bool.isRequired
};

export default HandleCard;
