import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import {
  Autorenew,
  BarChart,
  BatteryChargingFull,
  Fingerprint,
  LocalGasStation,
  Memory,
  Opacity
} from '@material-ui/icons';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import HandleCard from 'src/components/HandleCard';
import DoorsUsageChart from 'src/components/plots/DoorsUsageChart';
import useAPI from 'src/hooks/useAPI';
import { from, group, to, subheader } from 'src/utils/period';
import { assetSTM, batteryLevel, cartridgeLevel, esp, lastUpdate, mac, sni } from 'src/utils/validators';

const HandleSingle = () => {
  const { handleId } = useParams();
  const API = useAPI();
  const [period, setPeriod] = useState('CURRENT MONTH');
  const [last, setLast] = useState(null);
  const [uses, setUses] = useState({ left: null, right: null });
  const [infos, setInfos] = useState(null);
  const [telemetries, setTelemetries] = useState(null);
  const [cartridgesUsed, setCartridgesUsed] = useState({ left: null, right: null });
  const getLast = async () => {
    const data = await API.use(`door/${handleId}/last`, 'GET');
    setLast(data);
  };
  const getUses = async () => {
    const data = await API.use(`door/${handleId}/uses`, 'GET', { from: from(period).getTime(), to: to(period).getTime() });
    setUses(data);
  };
  const getInfos = async () => {
    const data = await API.use(`door/${handleId}/infos`, 'GET');
    setInfos(data);
  };
  const getTelemetries = async () => {
    const data = await API.use(`door/${handleId}/telemetries`, 'GET', { from: from(period).getTime(), to: to(period).getTime() });
    setTelemetries(data);
  };
  const getCartridgesUsed = async () => {
    const data = await API.use(`door/${handleId}/cartridgesUsed`, 'GET', { from: from(period).getTime(), to: to(period).getTime() });
    setCartridgesUsed(data);
  };

  const handleChange = (e) => {
    setPeriod(e.target.value);
  };

  useEffect(() => {
    setTelemetries(null);
    setUses({ left: null, right: null });
    setInfos(null);
    setLast(null);
    setCartridgesUsed({ left: null, right: null });
    getTelemetries();
    getUses();
    getInfos();
    getLast();
    getCartridgesUsed();
  }, [period]);

  return (
    <>
      <Helmet>
        <title>{last ? last.location : 'Loading...'}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { lg: 'row', xs: 'column' },
              justifyContent: { lg: 'space-between', xs: 'center' },
              alignItems: 'center',
              alignContent: 'center',
              marginBottom: 3,
            }}
          >
            <Typography variant="h2" gutterBottom sx={{ margin: 0, marginBottom: { xs: 2, lg: 0 } }}>{last ? last.location : 'Loading...'}</Typography>
            <Box style={{ textAlign: 'center' }}>
              <p>Last connection (L/R)</p>
              <p>{`${last && last.left ? lastUpdate(last.left.generationTimestamp) : '?'} / ${last && last.right ? lastUpdate(last.right.generationTimestamp) : '?'}`}</p>
            </Box>
            <Box>
              <Select
                value={period}
                sx={{ width: 200 }}
                variant="standard"
                onChange={(e) => handleChange(e)}
              >
                <MenuItem value="TODAY">TODAY</MenuItem>
                <MenuItem value="YESTERDAY">YESTERDAY</MenuItem>
                <MenuItem value="LAST 7 DAYS">LAST 7 DAYS</MenuItem>
                <MenuItem value="CURRENT MONTH">CURRENT MONTH</MenuItem>
                <MenuItem value="LAST MONTH">LAST MONTH</MenuItem>
                <MenuItem value="CURRENT YEAR">CURRENT YEAR</MenuItem>
                <MenuItem value="LAST YEAR">LAST YEAR</MenuItem>
              </Select>
            </Box>
          </Box>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={4}
              sm={6}
              xl={4}
              xs={12}
            >
              <HandleCard
                title="Cartridge Level (L/R)"
                loading={!last}
                value={`${cartridgeLevel(last).left}% / ${cartridgeLevel(last).right}%`}
                color="#83e48d"
                icon={<Opacity />}
              >
                {/* <Typography>Last update</Typography> */}
              </HandleCard>
            </Grid>
            <Grid
              item
              lg={4}
              sm={6}
              xl={4}
              xs={12}
            >
              <HandleCard
                title="Battery Level (L/R)"
                loading={!last}
                value={`${batteryLevel(last).left} / ${batteryLevel(last).right}`}
                color="#deaeff"
                icon={<BatteryChargingFull />}
              >
                {/* <Typography>Last update</Typography> */}
              </HandleCard>
            </Grid>
            <Grid
              item
              lg={4}
              sm={6}
              xl={4}
              xs={12}
            >
              <HandleCard
                title="Estimated uses left (L/R)"
                loading={!last}
                value={`${cartridgeLevel(last).left * 10} / ${cartridgeLevel(last).right * 10}`}
                color="#fbd66c"
                icon={<LocalGasStation />}
              >
                {/* <Typography>Last update</Typography> */}
              </HandleCard>
            </Grid>
            <Grid
              item
              lg={6}
              sm={6}
              xl={6}
              xs={12}
            >
              <HandleCard
                title="Total uses (L/R)"
                loading={_.isNull(uses.left) && _.isNull(uses.right)}
                value={`${uses.left} / ${uses.right}`}
                color="#6fb1fc"
                icon={<BarChart />}
              >
                <Typography>{_.startCase(_.camelCase(period))}</Typography>
              </HandleCard>
            </Grid>
            <Grid
              item
              lg={6}
              sm={6}
              xl={6}
              xs={12}
            >
              <HandleCard
                title="Cartridges used (L/R)"
                loading={_.isNull(cartridgesUsed.left) && _.isNull(cartridgesUsed.right)}
                value={`${cartridgesUsed.left} / ${cartridgesUsed.right}`}
                color="#ff9452"
                icon={<Autorenew />}
              >
                <Typography>{_.startCase(_.camelCase(period))}</Typography>
              </HandleCard>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Card sx={{ height: 600, maxHeight: 'max(100vh, 400px)' }}>
                <CardHeader
                  title="Usage analytics"
                  subheader={subheader(period)}
                />
                <Divider />
                <CardContent sx={{ width: '100%', height: '100%' }}>
                  <Box
                    sx={{
                      height: 'calc(100% - 54px)',
                      position: 'relative'
                    }}
                  >
                    <DoorsUsageChart
                      telemetries={telemetries}
                      loading={_.isNull(telemetries)}
                      from={from(period)}
                      to={to(period)}
                      group={group(period)}
                      level
                      debug
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              lg={6}
              sm={6}
              xl={6}
              xs={12}
            >
              <HandleCard
                title="Firmware version (L/R)"
                loading={!infos}
                value={(
                  <>
                    <Typography>{esp(infos)}</Typography>
                    <Typography>{assetSTM(infos)}</Typography>
                  </>
                )}
                color="#b3b3b1"
                icon={<Memory />}
              >
                <Typography>{`L: ${infos && infos.left ? lastUpdate(infos.left.sendingTimestamp) : '?'}`}</Typography>
                <Typography>{`R: ${infos && infos.right ? lastUpdate(infos.right.sendingTimestamp) : '?'}`}</Typography>
              </HandleCard>
            </Grid>
            <Grid
              item
              lg={6}
              sm={6}
              xl={6}
              xs={12}
            >
              <HandleCard
                title="Device infos (L/R)"
                loading={!infos}
                value={(
                  <>
                    <Typography>{mac(infos)}</Typography>
                    <Typography>{sni(infos)}</Typography>
                  </>
                )}
                color="#000"
                icon={<Fingerprint />}
              >
                <Typography>{`L: ${infos && infos.left ? lastUpdate(infos.left.sendingTimestamp) : '?'}`}</Typography>
                <Typography>{`R: ${infos && infos.right ? lastUpdate(infos.right.sendingTimestamp) : '?'}`}</Typography>
              </HandleCard>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default HandleSingle;
