import {
  Box, Button, Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton, Menu,
  MenuItem, TextField
} from '@material-ui/core';
import {
  ImportExport,
  MoreHoriz
} from '@material-ui/icons';
import { DatePicker, LocalizationProvider } from '@material-ui/lab';
import AdapterMoment from '@material-ui/lab/AdapterMoment';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Plus } from 'react-feather';
import useAPI from 'src/hooks/useAPI';

const EditMenuItem = ({ customer, afterSubmit }) => {
  const API = useAPI();
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState(customer.email);
  const [name, setName] = useState(customer.name);
  const [distributor, setDistributor] = useState(customer.distributor);
  const [address, setAddress] = useState(customer.address);
  const [zip, setZip] = useState(customer.zip);
  const [city, setCity] = useState(customer.city);
  const [country, setCountry] = useState(customer.country);
  const [phone, setPhone] = useState(customer.phone);

  const handleClickOpen = () => setOpen(true);
  const handleClickClose = () => setOpen(false);
  const handleSubmit = async () => {
    await API.use(
      'owners/create',
      'POST',
      {
        id: customer.id,
        email,
        name,
        distributor,
        address,
        zip,
        city,
        country,
        phone
      }
    );
    afterSubmit();
    handleClickClose();
  };

  return (
    <>
      <MenuItem onClick={handleClickOpen}>
        Edit Customer
      </MenuItem>
      <Dialog open={open} onClose={handleClickClose}>
        <DialogTitle>Create customer</DialogTitle>
        <DialogContent>
          <TextField variant="standard" label="Name" name="name" value={name} onChange={(e) => setName(e.target.value)} fullWidth />
          <TextField variant="standard" label="Address" name="address" value={address} onChange={(e) => setAddress(e.target.value)} fullWidth />
          <TextField variant="standard" label="Zip" name="zip" value={zip} onChange={(e) => setZip(e.target.value)} fullWidth />
          <TextField variant="standard" label="City" name="city" value={city} onChange={(e) => setCity(e.target.value)} fullWidth />
          <TextField variant="standard" label="Country" name="country" value={country} onChange={(e) => setCountry(e.target.value)} fullWidth />
          <TextField variant="standard" label="Phone" name="phone" value={phone} onChange={(e) => setPhone(e.target.value)} fullWidth />
          <TextField variant="standard" label="Email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} fullWidth />
          <TextField variant="standard" label="Distributor" name="lastName" value={distributor} onChange={(e) => setDistributor(e.target.value)} fullWidth />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Create customer</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

EditMenuItem.propTypes = {
  customer: PropTypes.object.isRequired,
  afterSubmit: PropTypes.func.isRequired,
};

const CustomersAdminCard = (rest) => {
  const API = useAPI();
  const [open, setOpen] = useState(false);
  const [customers, setCustomers] = useState([]);

  const [from, setFrom] = useState(moment().startOf('month'));
  const [to, setTo] = useState(moment().endOf('day'));

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [distributor, setDistributor] = useState('');
  const [address, setAddress] = useState('');
  const [zip, setZip] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [phone, setPhone] = useState('');

  const csvLink = useRef();

  const getCustomers = async (fromMoment, toMoment) => {
    const customersData = await API.use('owners', 'GET', { from: fromMoment.valueOf(), to: toMoment.valueOf() });
    setCustomers(customersData);
  };

  const handleClickOpen = () => setOpen(true);
  const handleClickClose = () => setOpen(false);
  const handleSubmit = () => {
    API.use(
      'owners/create',
      'POST',
      {
        email,
        name,
        distributor,
        address,
        zip,
        city,
        country,
        phone
      }
    )
      .then(() => handleClickClose());
  };

  useEffect(() => {
    getCustomers(from, to);
  }, [open, from, to]);

  const [showActions, setShowActions] = useState({});

  const openActions = (e, id) => {
    setShowActions({ ...showActions, [id]: e.target });
  };

  const closeActions = () => {
    setShowActions({});
  };

  const deleteCustomer = async (id) => {
    await API.use('owners/delete', 'POST', { id });
    await getCustomers(from, to);
  };

  const columns = [
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'address', headerName: 'Address', flex: 1 },
    { field: 'zip', headerName: 'Zip', flex: 1 },
    { field: 'city', headerName: 'City', flex: 1 },
    { field: 'country', headerName: 'Country', flex: 1 },
    { field: 'phone', headerName: 'Phone', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'distributor', headerName: 'Distributor', flex: 1 },
    {
      field: 'usesLeft',
      headerName: 'Uses (L)',
      type: 'number',
      flex: 1,
      valueGetter: ({ row }) => {
        const uses = row.uses;
        return `${uses.left ? uses.left : 0}`;
      }
    },
    {
      field: 'usesRight',
      headerName: 'Uses (R)',
      type: 'number',
      flex: 1,
      valueGetter: ({ row }) => {
        const uses = row.uses;
        return `${uses.right ? uses.right : 0}`;
      }
    },
    {
      field: 'actions',
      type: 'boolean',
      headerName: '...',
      disableExport: true,
      renderCell: (customer) => (
        <>
          <IconButton onClick={(e) => openActions(e, customer.id)}>
            <MoreHoriz />
          </IconButton>
          <Menu
            open={Boolean(showActions[customer.id])}
            onClose={() => closeActions()}
            anchorEl={showActions[customer.id]}
          >
            <EditMenuItem customer={customer} afterSubmit={() => getCustomers(from, to)} />
            <MenuItem onClick={() => deleteCustomer(customer.id)}>Delete customer</MenuItem>
          </Menu>
        </>
      )
    }
  ];

  return (
    <Card {...rest}>
      <CardHeader
        title="My Customers"
        action={(
          <>
            <IconButton onClick={handleClickOpen}>
              <Plus />
            </IconButton>
            <IconButton onClick={() => csvLink.current.link.click()}>
              <ImportExport />
            </IconButton>
            <CSVLink
              data={customers.map((customer) => ({ ...customer, uses: undefined, usesLeft: customer.uses.left, usesRight: customer.uses.right }))}
              filename={`${from.format('YYYY.MM.DD')}-${to.format('YYYY.MM.DD')}.csv`}
              style={{ display: 'none' }}
              ref={csvLink}
              target="_blank"
            />
          </>
        )}
      />
      <Divider />
      <Box sx={{ margin: 2, display: 'flex', justifyContent: 'space-between' }}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="From"
            value={from}
            onChange={setFrom}
            renderInput={(params) => <TextField {...params} variant="standard" />}
          />
          <DatePicker
            label="To"
            value={to}
            onChange={setTo}
            renderInput={(params) => <TextField {...params} variant="standard" />}
          />
        </LocalizationProvider>
      </Box>
      <Box>
        <DataGrid autoHeight columns={columns} rows={customers} />
      </Box>
      <Dialog open={open} onClose={handleClickClose}>
        <DialogTitle>Create customer</DialogTitle>
        <DialogContent>
          <TextField variant="standard" label="Name" name="name" value={name} onChange={(e) => setName(e.target.value)} fullWidth />
          <TextField variant="standard" label="Address" name="address" value={address} onChange={(e) => setAddress(e.target.value)} fullWidth />
          <TextField variant="standard" label="Zip" name="zip" value={zip} onChange={(e) => setZip(e.target.value)} fullWidth />
          <TextField variant="standard" label="City" name="city" value={city} onChange={(e) => setCity(e.target.value)} fullWidth />
          <TextField variant="standard" label="Country" name="country" value={country} onChange={(e) => setCountry(e.target.value)} fullWidth />
          <TextField variant="standard" label="Phone" name="phone" value={phone} onChange={(e) => setPhone(e.target.value)} fullWidth />
          <TextField variant="standard" label="Email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} fullWidth />
          <TextField variant="standard" label="Distributor" name="lastName" value={distributor} onChange={(e) => setDistributor(e.target.value)} fullWidth />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Create customer</Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default CustomersAdminCard;
